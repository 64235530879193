export enum AvailableProperties {
  Background = "--background",
  FontColor = "--font-color",
  HeaderColor = "--header",
  Sections = "--section",
  FontColor2 = "--font-color-2",
}

export enum Theme {
  Light = "Light",
  Dark = "Dark",
}

export interface IThemeOptions {
  name: Theme;
  customProperties: Record<AvailableProperties, string>;
}
