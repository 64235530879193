import { AvailableProperties, IThemeOptions, Theme } from "./Theme";

export const darkTheme: IThemeOptions = {
  name: Theme.Dark,
  customProperties: {
    [AvailableProperties.Background]: "#8b8694",
    [AvailableProperties.FontColor]: "darkgray",
    [AvailableProperties.HeaderColor]: "#272433",
    [AvailableProperties.Sections]: "#9e9ead",
    [AvailableProperties.FontColor2]: "#272433",
  },
};
