<div class="segmentBody">
  <img width="30" src='assets/tech.png'/>
  <h1 class='title'>Skills & Technologies</h1>
  <h4>The past 5 years I have primarily been using AWS serverless technologies to create event driven systems. Creating Typescript microservices with Lambda
     which integrate with API Gateway and DynamoDB. Also creating many integrations with EventBridge, SQS and many other services and utilizing CodePipeline/CodeBuild for CI/CD.

    Front end systems/projects i've been working on mostly built with Angular.

    All Infrastructure as Code deployed with CDK/Cloudformation.

    In previously roles I have used Java and SQL for a number of years.

  </h4>
  <h5>Currently using the following services daily in my current role:</h5>
  <ul>
    <li>Lambda</li>
    <li>Api Gateway</li>
    <li>S3</li>
    <li>Cognito</li>
    <li>SQS</li>
    <li>SNS</li>
    <li>EventBridge</li>
    <li>DynamoDB</li>
    <li>Athena</li>
    <li>Step Functions</li>
    <li>CodeBuild</li>
    <li>CodePipeline</li>
    <li>CodeDeploy</li>
    <li>CloudFormation</li>
    <li>AWS CDK</li>
    <li>AmazonAurora MySQL</li>
    <li>AppConfig</li>
  </ul>
</div>
