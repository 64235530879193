import { Component, OnInit } from "@angular/core";
import { ThemeService } from "../theme.service";
import { Theme } from "../Theme";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public isLightThemeActive: boolean = false;
  constructor(private themeService: ThemeService) {}

  ngOnInit() {}
  public toggleTheme(): void {
    this.isLightThemeActive = !this.isLightThemeActive;
    if (this.isLightThemeActive) {
      this.themeService.setTheme(Theme.Light);
    } else {
      this.themeService.setTheme(Theme.Dark);
    }
  }

  open() {
    window.open("https://jonynixon.co.uk/assets/jonynixoncv.pdf", "_blank");
  }
}
