<div class="segmentBody">
  <img width="26" src='assets/briefcase.png'/>
  <h1 class='title'>Work Experience</h1>
  <div>
    <h3>2018 - Present - Senior Software Developer &#64; CDL</h3>
    <h4>In this role i am a full stack developer leading a team of 4 developers working on an entirely cloud based system using serverless technology on AWS.
      Day to day i am using Angular, Typescript backed Lambda functions, DynamoDB and many other AWS service.
      The system has been built as serverless first and most of the microservices are event driven by the data that is ingested.
      During this time i have also taken AWS Associate & Professional exams to further enhance my AWS knowledge.
    </h4>
  </div>
  <div>
    <h3>2012 - 2018 - Intermediate Software Developer &#64; CDL</h3>
    <h4>I began this role still maintaining the legacy code base, but soon moved into a new team using Java and SQL migrating clients data from the legacy system to the new system.</h4>
  </div>
  <div>
    <h3>2011 - 2012 - Junior Software Developer &#64; CDL</h3>
    <h4>Maintaining and enhancing CDL legacy code base using FileTab-D & basic Python. Duties included adding new features whilst fixing and prioritising clients support issues.</h4>
  </div>
  <div>
    <h3>2008 - 2011 - Product Developer &#64; CDL<span></span></h3>
    <h4>Maintaining programmable documents for multiple insurance based systems.</h4>
  </div>
</div>
