import { AvailableProperties, IThemeOptions, Theme } from "./Theme";

export const lightTheme: IThemeOptions = {
  name: Theme.Light,
  customProperties: {
    [AvailableProperties.Background]: "#a8d3f1",
    [AvailableProperties.FontColor]: "#f1efef",
    [AvailableProperties.HeaderColor]: "#024665",
    [AvailableProperties.Sections]: "#75c1ef",
    [AvailableProperties.FontColor2]: "#f1efef",
  },
};
