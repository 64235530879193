<div class="segmentBody">
  <img width="30" src='assets/education.png'/>
  <h1 class='title'>Education</h1>
  <div style="margin-top: 5ch; text-align: center">
    <div class="container">
      <div class="info">
        <a href="https://www.credly.com/badges/8e68af1a-8c30-4bf7-ab18-5b7aba6c7085">
          <img class="image" width="100" src='assets/devopspro.png'/></a>
        <h5 class="info">July 2023 - AWS Devops Professional Exam</h5>
      </div>
    </div>
    <div class="container">
      <div class="info">
        <a href="https://www.credly.com/badges/2d9ff718-14ef-4043-95be-1cd50836d7fd">
          <img class="image" width="100" src='assets/solutions.png'/></a>
        <h5 class="info">May 2024 - AWS Solutions Architect Exam</h5>
      </div>
    </div>
    <div class="container">
      <div class="info">
        <a href="https://www.credly.com/badges/4f6dfc58-c771-4b4b-b6b7-0e871cf18cd9">
          <img class="image" width="100" src='assets/sysops.png'/></a>
        <h5 class="info">March 2024 - AWS Sysops Administrator Exam</h5>
      </div>
    </div>
    <div class="container">
      <div class="info">
        <a href="https://www.credly.com/badges/32c9619e-ea52-4546-99c1-31be5207afa2">
          <img class="image" width="100" src='assets/dev.png'/></a>
        <h5 class="info">August 2020 - AWS Associate Developer Exam</h5>
      </div>
    </div>
    <div class="container">
      <div class="info">
        <a><img class="image" width="100" src='assets/shu.png'/></a>
        <h5 class="info">2005 - 2008 - Computing Bsc Degree</h5>
      </div>
    </div>
    <div>
    </div>
  </div>
</div>

