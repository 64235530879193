import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { EducationComponent } from "./education/education.component";
import { SkillsComponent } from "./skills/skills.component";
import { WorkExperienceComponent } from "./work-experience/work-experience.component";
import { MainComponent } from "./main/main.component";
import { ProjectsComponent } from "./projects/projects.component";
import { ThemeService } from "./theme.service";
import { ThemeDirective } from "./theme-directive";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    EducationComponent,
    SkillsComponent,
    WorkExperienceComponent,
    MainComponent,
    ProjectsComponent,
    ThemeDirective,
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [ThemeService],

  bootstrap: [AppComponent],
})
export class AppModule {}
