<div class="dev">
  <img src='assets/developer.png'/>
  <h1 class='name'>Jonathan Nixon</h1>
  <h3>Senior Software Developer</h3>
  <h4 class='title'>Experienced developer with over 10 years experience. I am keen on learning and using the latest
    technologies to build new and exciting applications.</h4>
  <a (click)="open()">
    <img title="Curriculum Vitae" class="image" width="25" src='assets/pdf.png'/></a>
  <a (click)="toggleTheme()" style="margin-left: 1ch"> <img title="Toggle theme" class="image" width="35" src='assets/theme.png'/> </a>
</div>
