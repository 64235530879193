<div class="container">
  <div class="section">
    <app-skills>/</app-skills>
  </div>
  <div class="section">
    <app-education></app-education>
  </div>
  <div class="section">
    <app-work-experience></app-work-experience>
  </div>
  <div class="section">
  <app-projects></app-projects>
  </div>
</div>
