import { Component } from "@angular/core";
import { ThemeService } from "./theme.service";
import { Theme } from "./Theme";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "portfolio";
}
